import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { RawWidgetConfigEventDefFragment } from '@gql/fragments/__generated/RawWidgetConfigEventDef';
import type { EventFilter } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';
import { WhlModuleType } from '@models/WhlModuleType';

export default (
  widgetConfigRef: MaybeRefOrGetter<Nullable<RawWidgetConfigFragment>>
): Ref<EventFilter> => {
  const typeConfigRef = useWidgetTypeConfig(
    widgetConfigRef,
    WhlModuleType.EVENT
  ) as Ref<RawWidgetConfigEventDefFragment>;

  return computed((): EventFilter => {
    const widgetConfig = toValue(widgetConfigRef);
    if (isNotEmpty(widgetConfig)) {
      return rawBuildEventBaseFilter(widgetConfig, toValue(typeConfigRef));
    }
    return {};
  });
};
