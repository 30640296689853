import dayjs from 'dayjs';
import type { RawWidgetConfigFragment } from '../../gql/fragments/__generated/RawWidgetConfig';
import type { RawWidgetConfigEventDefFragment } from '../../gql/fragments/__generated/RawWidgetConfigEventDef';
import type { EventFilter } from '../../gql/schema';
import { WhlDateSearchType } from '../../models/WhlDateSearchType';
import { WhlLocationSearchType } from '../../models/WhlLocationSearchType';
import rawIsEmpty from './rawIsEmpty';
import rawIsNotEmpty from './rawIsNotEmpty';
import rawMapToIds from './rawMapToIds';

export default (
  widgetConfig: RawWidgetConfigFragment,
  typeConfig: RawWidgetConfigEventDefFragment
): EventFilter => {
  if (rawIsEmpty(widgetConfig)) return {};

  const baseFilter: EventFilter = {};

  // client restriction
  if ((widgetConfig?.client?.id ?? 0) > 0) {
    baseFilter.client = {
      id: { oneOf: [widgetConfig.client?.id ?? -1] },
    };

    if (widgetConfig.includeSubClients === true) {
      baseFilter.client.includeSubClients = true;
    }
  }

  if (typeConfig) {
    // categories
    if (rawIsNotEmpty(typeConfig.searchCategories)) {
      baseFilter.categories = {
        oneOf: rawMapToIds(typeConfig.searchCategories),
      };
    }

    // criteria
    if (rawIsNotEmpty(typeConfig.searchCriteria)) {
      baseFilter.criteria = {
        oneOf: rawMapToIds(typeConfig.searchCriteria),
      };
    }

    // date range
    if ((typeConfig.dateSearchType ?? 0) > 0) {
      const nextWeekend = buildDateRangeForNextWeekend();

      switch (typeConfig.dateSearchType) {
        case WhlDateSearchType.FixedDate:
          baseFilter.fromDate = typeConfig.fixedStartDate;
          baseFilter.toDate = typeConfig.fixedEndDate;
          break;
        case WhlDateSearchType.RelativeDate:
          baseFilter.fromDate = dayjs()
            .add(parseInt(typeConfig.intervalFrom ?? '0', 10), 'days')
            .format('YYYY-MM-DD');
          baseFilter.toDate = dayjs()
            .add(parseInt(typeConfig.intervalTo ?? '7', 10), 'days')
            .format('YYYY-MM-DD');
          break;
        case WhlDateSearchType.NextWeekend:
          baseFilter.fromDate = nextWeekend.fromDate.format('YYYY-MM-DD');
          baseFilter.toDate = nextWeekend.toDate.format('YYYY-MM-DD');

          break;
      }
    }

    // event date tags
    if (rawIsNotEmpty(typeConfig.tags)) {
      baseFilter.eventDateTags = {
        oneOf: rawMapToIds(typeConfig.tags),
      };
    }

    // start time
    if (rawIsNotEmpty(typeConfig.startTime)) {
      baseFilter.startTime = { from: typeConfig.startTime };
    }

    // location
    if (
      rawIsNotEmpty(typeConfig.locationSearchType) &&
      typeConfig.locationSearchType! >= 0
    ) {
      switch (typeConfig.locationSearchType) {
        case WhlLocationSearchType.EventLocation:
          if (rawIsNotEmpty(typeConfig.locations)) {
            baseFilter.eventLocation = {
              id: { oneOf: rawMapToIds(typeConfig.locations ?? []) },
            };
          }
          break;
        case WhlLocationSearchType.AddressPoiGroup:
          if (rawIsNotEmpty(typeConfig.poiGroups)) {
            baseFilter.eventLocation = {
              group: { oneOf: rawMapToIds(typeConfig.poiGroups ?? []) },
            };
          }
          break;
        case WhlLocationSearchType.Location:
          if (rawIsNotEmpty(typeConfig.location)) {
            baseFilter.eventLocation = {
              location: { oneOf: rawMapToIds([typeConfig.location]) },
            };
          }
          break;
        case WhlLocationSearchType.Coordinates:
          if (
            typeConfig.coordinates &&
            typeConfig.coordinates.latitude &&
            typeConfig.coordinates.latitude != 0 &&
            typeConfig.coordinates.longitude &&
            typeConfig.coordinates.longitude != 0 &&
            (typeConfig.radius ?? 0) > 0
          ) {
            baseFilter.geoFilter = {
              distanceFromPoint: {
                point: {
                  latitude: typeConfig.coordinates?.latitude as number,
                  longitude: typeConfig.coordinates?.longitude as number,
                },
                maxDistance: typeConfig.radius as number,
              },
            };
          }
          break;
        case WhlLocationSearchType.ZipCode:
          if (rawIsNotEmpty(typeConfig.zipcodes)) {
            const zipCodes = (typeConfig.zipcodes ?? '').split(',');
            if (zipCodes.length > 0) {
              baseFilter.eventLocation = {
                zipcode: { oneOf: zipCodes },
              };
            }
          }
          break;
        case WhlLocationSearchType.Region:
          if (rawIsNotEmpty(typeConfig.region)) {
            baseFilter.eventLocation = {
              regions: { oneOf: rawMapToIds([typeConfig.region]) },
            };
          }
          break;
      }
    }

    // event series
    if (rawIsNotEmpty(typeConfig.series)) {
      baseFilter.series = {
        oneOf: rawMapToIds(typeConfig.series),
      };
    }

    // one-off events
    if (typeConfig.oneOffEvents) {
      baseFilter.onlyOneOffs = true;
    }

    // admission free events
    if (typeConfig.freeEntryEvents) {
      baseFilter.pricingFilter = {
        freeOfCharge: true,
      };
    }
  }

  return baseFilter;
};
